import React, { useState, useEffect } from 'react';

// import Grid from '@material-ui/core/Grid';
// import Divider from '@material-ui/core/Divider';
import InputAdornment from '@material-ui/core/InputAdornment';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import ClearIcon from '@material-ui/icons/Clear';
import { isMobile } from 'react-device-detect';

import { constants, utilities } from '@aim/common';

import {
  AimIconAndTextButton,
  AimTypography,
  AimSelect,
  AimSelectMenuItem,
  AimTextField,
} from '../../atoms';

import { AimNumericInput } from '../../bundles';

import { theme } from '../../../theme';
import { styled } from '../../styled';

// import { getEndDateOfEvent } from './agencyPaymentGqlHelper';
import { isBefore } from 'date-fns';
// import { useDataHelper } from '../../../utils/hooks/dataHelper';

// test data
// import multiCartTestData from './multiCartTest.json';
import discountTestCodes from './discountTestCodes.json';

const Container = styled('div')(({ cartVariant }) => ({
  background: theme.colors.greyScale.white,
  padding: 20,
  marginBottom: 20,
  ...cartVariant,
}));

const CartRow = styled('div')((props) => ({
  display: 'flex',
  justifyContent: 'space-between',
  borderBottom: props.border && `1px solid ${theme.colors.greyScale.grey2}`,
}));

const CustomTextField = styled(AimTextField)({
  color: 'green',
  '& div label': {
    color: 'green',
  },
});

// const SubTotal = styled('div')({
//   width: '25%',
//   margin: '50px 0 20px auto',
// });

const PAYMENT_GATEWAY = {
  MONETAONLINE: 1,
  INGENICO: 2,
  GPWEBPAY: 3,
};

const paymentOptions = [
  {
    value: PAYMENT_GATEWAY.MONETAONLINE,
    name: 'MonetaOnline',
  },
  {
    value: PAYMENT_GATEWAY.INGENICO,
    name: 'Ingenico',
  },
  {
    value: PAYMENT_GATEWAY.GPWEBPAY,
    name: 'Gp Webpay',
  },
];

const variants = {
  primary: {
    backgroundColor: theme.colors.greyScale.backgroundGrey,
  },
  secondary: {
    backgroundColor: theme.colors.primary.white,
  },
};

const getReverseVariant = ({ variant }) => {
  return variant === 'primary'
    ? { ...getVariant({ variant: 'secondary' }) }
    : { ...getVariant({ variant: 'primary' }) };
};

const getVariant = ({ variant }) => {
  return variants[variant];
};

const Checkout = ({
  onClickConfirm,
  onHandlePayment,
  i18n,
  variant = 'primary',
  data,
  // autoBuy = false,
}) => {
  // Hooks
  // const { decodeDbNumber, vatCalc, safeNum, roundTo2Decimal } = useDataHelper();

  // States
  // const [isEventEnd, setIsEventEnd] = useState([]);
  const [multiCartData, setMultiCartData] = useState(data);

  // const calcDiscountAmount = (data) => {
  //   return data.discount.codeType === 'PERC'
  //     ? (data.unitaryPrice * data.quantity.value * data.discount.codeValue) /
  //         100
  //     : data.discount.codeValue;
  // };

  const calcTotalAmounts = (item) => {
    // let discountAmount = 0;
    // if (item.discount?.found > 0) {
    //   discountAmount = calcDiscountAmount(item);
    // }

    const total =
      Number(item.unitaryPrice * item.quantity?.value) +
      (item.vat?.vatRate1
        ? percentage(
            item.vat.vatRate1,
            item.vat.taxableVatRate1 * item.quantity?.value
          )
        : 0) +
      (item.vat?.vatRate2
        ? percentage(
            item.vat.vatRate2,
            item.vat.taxableVatRate2 * item.quantity?.value
          )
        : 0);

    // const totalToPay = total - discountAmount;
    // return { total, totalToPay, discount: { amount: discountAmount } };
    return { total, totalToPay: total, discount: { amount: 0 } };
  };

  const calcTotalsDiscountAmount = (nextMultiCartData) => {
    return nextMultiCartData.totals.discount.codeType === 'PERC'
      ? (nextMultiCartData.totals.total *
          nextMultiCartData.totals.discount.codeValue) /
          100
      : nextMultiCartData.totals.discount.codeValue;
  };

  const calcTotals = (nextMultiCartData) => {
    const totals = nextMultiCartData.services.reduce(
      (res, curr) =>
        (res = {
          total: res.totalToPay + curr.totalToPay,
          totalToPay: res.totalToPay + curr.totalToPay,
        })
    );

    nextMultiCartData.totals.total = totals.total;
    nextMultiCartData.totals.totalToPay = totals.totalToPay;

    let discountTotalsAmount = 0;
    if (nextMultiCartData.totals.discount?.found > 0) {
      discountTotalsAmount = calcTotalsDiscountAmount(nextMultiCartData);
    }

    totals.totalToPay -= discountTotalsAmount;
    return { ...totals, discount: { amount: discountTotalsAmount } };
  };

  //Effects
  useEffect(() => {
    const isEndBeforeToday = isBefore(
      new Date(multiCartData?.event?.endDate),
      new Date()
    );
    // setIsEventEnd(isEndBeforeToday);

    const totals = {
      total: 0,
      totalToPay: 0,
      discount: { amount: 0, disabled: multiCartData.totals.discount.disabled },
    };
    const nextServices = multiCartData.services.map((item, index) => {
      const priceVatRate1 = (item.vat?.vatRate1
        ? percentage(item.vat.vatRate1, item.vat.taxableVatRate1)
        : 0
      ).toFixed(2);
      const priceVatRate2 = (item.vat?.vatRate2
        ? percentage(item.vat.vatRate2, item.vat.taxableVatRate2)
        : 0
      ).toFixed(2);

      // let discountAmount = 0;
      // if (item.discount?.found) {
      //   discountAmount = calcDiscountAmount(item);
      // }

      const nextItem = {
        ...item,
        isEventEnd: isEndBeforeToday,
        label: item.serviceLabel,
        descr: item.serviceDescr,
        servicesList: item.servicesIncluded.reduce(
          (res, curr, index) =>
            (res += `${curr.title}${
              index < item.servicesIncluded.length - 1 ? ', ' : ''
            }`),
          ''
        ),
        unitaryPrice: item.netPrice,
        netPrice: item.netPrice * item.quantity.value,
        priceVatRate1,
        priceVatRate2,
        quantity: item.quantity,
      };

      // amounts
      const amounts = calcTotalAmounts(nextItem);
      totals.total += amounts.total;
      totals.totalToPay += amounts.totalToPay;
      return {
        ...nextItem,
        discount: {
          amount: amounts.discount.amount,
          disabled: item.discount?.disabled,
        },
        total: amounts.total,
        totalToPay: amounts.totalToPay,
      };
    });

    const nextMultiCartData = {
      ...multiCartData,
      totals,
      services: nextServices,
    };
    setMultiCartData(nextMultiCartData);
  }, []);

  // useEffect(() => {
  //   if (autoBuy && multiCartData.totals?.totalToPay) {
  //     onClickConfirm(multiCartData.totals?.totalToPay);
  //   }
  // }, [autoBuy, multiCartData]);

  const percentage = (vat, totalValue) => {
    const perc = (totalValue / 100) * vat;
    return utilities.roundTo2Decimal(perc);
  };

  //   const onDeleteRow = (id) =>
  //     setCheckoutBookings([...checkoutBookings].filter((cb) => cb.id !== id));

  const handleQuantityClick = ({ maxQ, index, value, isClick }) => {
    const quantityValue = isClick
      ? multiCartData.services[index].quantity?.value + value
      : value;
    // check quantity value
    if (quantityValue > 0 && quantityValue <= maxQ) {
      const nextMultiCartData = { ...multiCartData };
      // nextMultiCartData.services[index].quantity = nextQuantity;
      // nextMultiCartData.services[index].netPrice =
      const nextQuantity = multiCartData.services[index].quantity;

      const nextItem = {
        ...multiCartData.services[index],
        netPrice: quantityValue * multiCartData.services[index].unitaryPrice,
        quantity: { ...nextQuantity, value: quantityValue },
      };

      // item/service total
      const amounts = calcTotalAmounts(nextItem, index);
      nextMultiCartData.services[index] = {
        ...nextItem,
        total: amounts.total,
        totalToPay: amounts.totalToPay,
        discount: {
          ...multiCartData.services[index].discount,
          amount: amounts.discount.amount,
        },
      };

      // totals and discount
      const totalsAmounts = calcTotals(nextMultiCartData);
      const nextTotals = {
        ...nextMultiCartData.totals,
        total: totalsAmounts.total,
        totalToPay: totalsAmounts.totalToPay,
        discount: {
          ...nextMultiCartData?.totals.discount,
          amount: totalsAmounts.discount.amount,
        },
      };

      setMultiCartData({
        ...nextMultiCartData,
        totals: nextTotals,
      });
    }
  };

  const handleSelectDiscountCode = (value, index) => {
    const nextMultiCartData = { ...multiCartData };
    const nextItem = {
      ...multiCartData.services[index],
      selectedDiscount: value,
      discount: {
        codeName: value.codeName,
        // codeValue: value.codeValue,
        // codeType: value.codeType,
      },
    };
    nextMultiCartData.services[index] = { ...nextItem };

    setMultiCartData({
      ...nextMultiCartData,
      totals: calcTotals(nextMultiCartData),
    });
  };

  const handleDiscountApply = (e, index) => {
    if (e.key === 'Enter') {
      const foundDiscount = discountTestCodes.codes.find(
        (x) => x.codeName === e.target.value
      );
      const nextMultiCartData = { ...multiCartData };
      const nextItem = {
        ...multiCartData.services[index],
        selectedDiscount: foundDiscount,
        discount: {
          ...multiCartData.services[index].discount,
          codeValue: foundDiscount?.codeValue,
          codeType: foundDiscount?.codeType,
          found: foundDiscount ? 1 : 0,
        },
      };

      const amounts = calcTotalAmounts(nextItem, index);
      nextMultiCartData.services[index] = {
        ...nextItem,
        total: amounts.total,
        totalToPay: amounts.totalToPay,
        discount: {
          ...multiCartData.services[index].discount,
          ...nextItem.discount,
          amount: amounts.discount.amount,
        },
      };

      const totalAmounts = calcTotals(nextMultiCartData);
      nextMultiCartData.totals = {
        total: totalAmounts.total,
        totalToPay: totalAmounts.totalToPay,
        discount: {
          ...nextMultiCartData.totals.discount,
          amount: totalAmounts.discount.amount,
        },
      };
      setMultiCartData(nextMultiCartData);
    }
  };

  const handleChangeDiscount = (e, index) => {
    const nextMultiCartData = { ...multiCartData };
    const nextItem = {
      ...multiCartData.services[index],
      discount: {
        ...multiCartData.services[index].discount,
        codeName: e.target.value,
      },
    };
    nextMultiCartData.services[index] = { ...nextItem };
    setMultiCartData(nextMultiCartData);
  };

  const handleClearDiscount = (index) => {
    const nextMultiCartData = { ...multiCartData };
    const nextItem = {
      ...multiCartData.services[index],
      discount: { codeName: '', codeType: '', found: -1 },
    };

    nextMultiCartData.services[index] = nextItem;

    const amounts = calcTotalAmounts(nextItem, index);
    nextMultiCartData.services[index] = {
      ...nextItem,
      total: amounts.total,
      totalToPay: amounts.totalToPay,
      discount: {
        ...multiCartData.services[index].discount,
        ...nextItem.discount,
        amount: amounts.discount.amount,
      },
    };

    const totalsAmounts = calcTotals(nextMultiCartData);
    nextMultiCartData.totals = {
      total: totalsAmounts.total,
      totalToPay: totalsAmounts.totalToPay,
      discount: {
        ...nextMultiCartData.totals.discount,
        amount: totalsAmounts.discount.amount,
      },
    };
    setMultiCartData(nextMultiCartData);
  };

  const handleSelectTotalsDiscountCode = (e) => {
    const nextTotals = { ...multiCartData.totals };
    nextTotals.discount.codeName = e.target.value.codeName;
    nextTotals.selectedDiscount = e.target.value;
    setMultiCartData({ ...multiCartData, totals: nextTotals });
  };

  const handleTotalsDiscountApply = (e) => {
    if (e.key === 'Enter') {
      const foundDiscount = discountTestCodes.codes.find(
        (x) => x.codeName === e.target.value
      );
      const nextMultiCartData = { ...multiCartData };
      nextMultiCartData.totals.discount = {
        ...multiCartData.totals.discount,
        codeValue: foundDiscount?.codeValue,
        codeType: foundDiscount?.codeType,
        found: foundDiscount ? 1 : 0,
      };
      const totalAmounts = calcTotals(nextMultiCartData);
      nextMultiCartData.totals = {
        total: totalAmounts.total,
        totalToPay: totalAmounts.totalToPay,
        discount: {
          ...nextMultiCartData.totals.discount,
          amount: totalAmounts.discount.amount,
        },
      };
      setMultiCartData(nextMultiCartData);
    }
  };

  const handleChangeTotalsDiscount = (e) => {
    const nextMultiCartData = { ...multiCartData };
    nextMultiCartData.totals.discount = {
      ...multiCartData.totals.discount,
      codeName: e.target.value,
    };
    setMultiCartData(nextMultiCartData);
  };

  const handleClearTotalsDiscount = () => {
    const nextMultiCartData = { ...multiCartData };
    const nextTotals = {
      ...multiCartData.totals,
      discount: { codeName: '', codeType: '', found: -1 },
    };
    nextMultiCartData.totals = nextTotals;

    const totalsAmounts = calcTotals(nextMultiCartData);
    nextMultiCartData.totals = {
      total: totalsAmounts.total,
      totalToPay: totalsAmounts.totalToPay,
      discount: {
        ...nextMultiCartData.totals.discount,
        amount: totalsAmounts.discount.amount,
      },
    };
    setMultiCartData(nextMultiCartData);
  };

  return (
    <div>
      {multiCartData.event.isEventEnd && (
        <div>
          <AimTypography variant="text" style={{ color: 'red' }}>
            {i18n.checkoutAlerts.eventIsOver.label}
          </AimTypography>
        </div>
      )}
      <div
        style={{ ...getReverseVariant({ variant }), margin: 10, padding: 30 }}
      >
        {/* {checkoutBookings.map((cb) => (
          <CheckoutRow key={cb.id} booking={cb} onDelete={onDeleteRow} />
        ))} */}

        {multiCartData?.services?.map((item, index) => {
          return (
            <Container
              cartVariant={{
                ...getVariant({ variant }),
              }}
              key={item.id}
            >
              <CartRow>
                <div style={{ maxWidth: '40%' }}>
                  <AimTypography variant="textBold">
                    {i18n.page.service.label}
                  </AimTypography>
                </div>
                <div
                  style={{
                    width: isMobile ? '55%' : '40%',
                    display: 'flex',
                    justifyContent: 'space-between',
                  }}
                >
                  <AimTypography variant="textBold">
                    {i18n.page.unitaryPrice.label}
                  </AimTypography>
                  <AimTypography variant="textBold" style={{ width: '120px' }}>
                    {i18n.page.quantity.label}
                  </AimTypography>
                  <AimTypography variant="textBold">
                    {i18n.page.price.label}
                  </AimTypography>
                </div>
              </CartRow>
              {/* <AimTypography>{i18n.page.ticket.label}</AimTypography> */}
              <CartRow>
                <div style={{ maxWidth: '40%' }}>
                  <AimTypography variant="text" boxStyle={{ fontSize: '21px' }}>
                    {item?.label}
                  </AimTypography>
                </div>
                <div
                  style={{
                    width: isMobile ? '55%' : '40%',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  <AimTypography variant="text">
                    {Number(item.unitaryPrice).toFixed(2)}
                  </AimTypography>
                  <AimNumericInput
                    // inline
                    value={item.quantity.value}
                    editableInput
                    onChange={(value) =>
                      handleQuantityClick({
                        maxQ: item.quantity.max,
                        index,
                        value,
                        isClick: false,
                      })
                    }
                    onClickPlus={() => handleQuantityClick(index, 1, true)}
                    onClickMinus={() =>
                      handleQuantityClick(
                        index,
                        (item.quantity.value > 1 && -1) || 0,
                        true
                      )
                    }
                    disabledMinus={item.quantity.value === 1}
                    disabledPlus={item.quantity.value >= item.quantity.max}
                    formControlStyle={{
                      padding: 0,
                      margin: 0,
                      minWidth: 30,
                      flex: 0,
                    }}
                    fieldStyle={{
                      minHeight: 30,
                    }}
                    disabled={item.quantity.readonly}
                  />
                  <AimTypography variant="text">
                    {Number(item.netPrice).toFixed(2)}
                  </AimTypography>
                </div>
              </CartRow>
              {item?.servicesList !== '' && (
                <CartRow border>
                  <AimTypography variant="text" style={{ maxWidth: '40%' }}>
                    {i18n.page.servicesIncluded.label}: {item?.servicesList}
                  </AimTypography>
                </CartRow>
              )}
              {item?.descr && (
                <CartRow border>
                  <AimTypography variant="text" style={{ maxWidth: '40%' }}>
                    {item?.descr}
                  </AimTypography>
                </CartRow>
              )}
              <div>
                {item.vat?.vatRate1 > 0 ? (
                  <CartRow border style={{ marginTop: 20 }}>
                    <div style={{ display: 'flex' }}>
                      <AimTypography variant="textBold">
                        {i18n.checkoutTable.summary.vat.label}
                      </AimTypography>
                      <AimTypography variant="text">
                        {`(
                      ${item?.vat?.vatRate1}% ${i18n.checkoutTable.summary.on.label} ${item?.vat?.taxableVatRate1}€)`}
                        {/* {`${i18n.page.vat} (${fee?.vatRate}%)`} */}
                      </AimTypography>
                    </div>
                    <AimTypography variant="text">
                      {Number(
                        item.priceVatRate1 * item.quantity?.value
                      ).toFixed(2)}
                    </AimTypography>
                  </CartRow>
                ) : null}
                {item.vat?.vatRate2 > 0 ? (
                  <CartRow border>
                    <div style={{ display: 'flex' }}>
                      <AimTypography variant="textBold">
                        {i18n.checkoutTable.summary.vat.label}
                      </AimTypography>
                      <AimTypography variant="text">
                        {`(
                      ${item?.vat?.vatRate2}% ${i18n.checkoutTable.summary.on.label} ${item?.vat?.taxableVatRate2}€)`}
                      </AimTypography>
                    </div>
                    <AimTypography variant="text">
                      {Number(
                        item.priceVatRate2 * item.quantity?.value
                      ).toFixed(2)}
                    </AimTypography>
                  </CartRow>
                ) : null}
                {item.vat?.vatRate1 > 0 || item.vat?.vatRate2 > 0 ? (
                  <CartRow style={{ marginBottom: 60 }}>
                    <AimTypography variant="text">
                      {i18n.page.vatDescr.label}
                    </AimTypography>
                  </CartRow>
                ) : null}
                <CartRow border style={{ marginTop: 20 }}>
                  <AimTypography variant="textBold">
                    {i18n.page.total.label}
                  </AimTypography>
                  <AimTypography variant="text">
                    {Number(item?.total).toFixed(2)}
                  </AimTypography>
                </CartRow>
                {!item.discount.disabled ? (
                  <>
                    <CartRow>
                      <AimTypography variant="textBold">
                        {i18n.page.discountCode.label}
                      </AimTypography>
                      <div
                        style={
                          isMobile
                            ? {
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'end',
                              }
                            : { maxWidth: '50%' }
                        }
                      >
                        <AimSelect
                          value={item.selectedDiscount}
                          displayLabel={false}
                          onChange={(e) => {
                            handleSelectDiscountCode(e.target.value, index);
                          }}
                          displayEmpty
                          formControlStyle={{
                            width: 220,
                            marginTop: 10,
                            display: multiCartData.test.discountSelect
                              ? 'inline-flex;'
                              : 'none',
                          }}
                          inputProps={{ 'aria-label': 'Without label' }}
                          selectVariant={
                            variant === 'primary' ? 'secondary' : 'primary'
                          }
                          selectPlaceholder="Choose discount code"
                        >
                          {discountTestCodes &&
                            discountTestCodes.codes.map((item) => (
                              <AimSelectMenuItem
                                key={item.codeValue}
                                value={item}
                              >
                                {item.codeName}
                              </AimSelectMenuItem>
                            ))}
                        </AimSelect>
                        {item.discount && (
                          <AimTextField
                            textfieldVariant={
                              variant === 'primary' ? 'secondary' : 'primary'
                            }
                            formControlStyle={{
                              width: '250px',
                              flex: 'none',
                              flexDirection: 'column-reverse',
                            }}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <ClearIcon
                                    style={{ cursor: 'pointer' }}
                                    onClick={() => handleClearDiscount(index)}
                                  />
                                </InputAdornment>
                              ),
                            }}
                            customLabel={
                              <AimTypography
                                variant="text"
                                style={
                                  item.discount?.found === 1
                                    ? { color: 'green' }
                                    : { color: 'black' }
                                }
                              >
                                {item.discount?.found >= 0
                                  ? item.discount?.found === 1
                                    ? 'Discount correctly applied'
                                    : 'Discount not found'
                                  : 'Insert code and press return to apply'}
                              </AimTypography>
                            }
                            onChange={(e) => {
                              handleChangeDiscount(e, index);
                            }}
                            onKeyDown={(e) => {
                              handleDiscountApply(e, index);
                            }}
                            value={item.discount.codeName}
                            placeholder="insert discount code"
                            // label="insert code and press return to apply"
                          />
                        )}
                      </div>
                    </CartRow>
                    {item?.discount && (
                      <CartRow border>
                        <AimTypography variant="textBold">
                          {i18n.page.discount.label}
                        </AimTypography>
                        <AimTypography variant="text">
                          {Number(item.discount.amount || 0).toFixed(2)}
                        </AimTypography>
                      </CartRow>
                    )}
                  </>
                ) : null}
                <CartRow border>
                  <AimTypography variant="textBold">
                    {i18n.page.totalToPay.label}
                  </AimTypography>
                  <AimTypography variant="h4">
                    {Number(item?.totalToPay).toFixed(2)}
                  </AimTypography>
                </CartRow>
                {multiCartData?.services.length > 1 ? (
                  <div
                    style={{
                      display: 'flex',
                      flex: 1,
                      justifyContent: 'flex-end',
                      marginTop: 10,
                    }}
                  >
                    <AimIconAndTextButton
                      isUpperCase
                      variant="yellowFill"
                      text={i18n.page.buy.label}
                      // onClick={() => purchaseTicket()}
                      disabled={
                        // item.event?.isEventEnd ||
                        !multiCartData?.paymentType
                      }
                    >
                      <ShoppingCartIcon />
                    </AimIconAndTextButton>
                  </div>
                ) : null}
              </div>
            </Container>
          );
        })}
        <CartRow border>
          <AimTypography variant="textBold">
            {i18n.page.total.label}
          </AimTypography>
          <AimTypography variant="text">
            {Number(multiCartData.totals?.total).toFixed(2)}
          </AimTypography>
        </CartRow>
        {!multiCartData.totals.discount.disabled ? (
          <>
            <CartRow>
              <AimTypography variant="textBold">
                {i18n.page.discountCode.label}
              </AimTypography>
              <div style={isMobile ? { maxWidth: '60%' } : { maxWidth: '50%' }}>
                <AimSelect
                  value={multiCartData.totals?.selectedDiscount}
                  displayLabel={false}
                  onChange={handleSelectTotalsDiscountCode}
                  displayEmpty
                  formControlStyle={{
                    width: 220,
                    marginTop: 10,
                    display: multiCartData.test.discountSelect
                      ? 'inline-flex;'
                      : 'none',
                  }}
                  inputProps={{ 'aria-label': 'Without label' }}
                  selectVariant={variant}
                  selectPlaceholder="Choose discount code"
                >
                  {discountTestCodes &&
                    discountTestCodes.codes.map((item) => (
                      <AimSelectMenuItem key={item.codeValue} value={item}>
                        {item.codeName}
                      </AimSelectMenuItem>
                    ))}
                </AimSelect>
                {multiCartData.totals?.discount && (
                  <AimTextField
                    textfieldVariant={variant}
                    formControlStyle={{
                      width: '250px',
                      flex: 'none',
                      flexDirection: 'column-reverse',
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <ClearIcon
                            style={{ cursor: 'pointer' }}
                            onClick={handleClearTotalsDiscount}
                          />
                        </InputAdornment>
                      ),
                    }}
                    customLabel={
                      <AimTypography
                        variant="text"
                        style={
                          multiCartData.totals?.discount?.found === 1
                            ? { color: 'green' }
                            : { color: 'black' }
                        }
                      >
                        {multiCartData.totals?.discount?.found >= 0
                          ? multiCartData.totals?.discount?.found === 1
                            ? 'Discount correctly applied'
                            : 'Discount not found'
                          : 'Insert code and press return to apply'}
                      </AimTypography>
                    }
                    onChange={handleChangeTotalsDiscount}
                    onKeyDown={handleTotalsDiscountApply}
                    value={multiCartData.totals?.discount.codeName}
                    placeholder="insert discount code"
                    // label="insert code and press return to apply"
                  />
                )}
              </div>
            </CartRow>

            {multiCartData.totals?.discount && (
              <CartRow border>
                <AimTypography variant="textBold">
                  {i18n.page.discount.label}
                </AimTypography>
                <AimTypography variant="text">
                  {Number(multiCartData.totals?.discount.amount || 0).toFixed(
                    2
                  )}
                </AimTypography>
              </CartRow>
            )}
          </>
        ) : null}
        <CartRow border>
          <AimTypography variant="h4">
            {i18n.page.totalToPay.label}
          </AimTypography>
          <AimTypography variant="h4">
            {Number(multiCartData.totals?.totalToPay).toFixed(2)}
          </AimTypography>
        </CartRow>
        {multiCartData?.paymentType === constants.PaymentTypes.BankTransfer ? (
          <>
            <CartRow style={{ marginTop: '20px' }}>
              <div>
                <AimTypography variant="textBold">
                  {i18n.page.bankTransferIndication.label}
                </AimTypography>
                <AimTypography variant="text">
                  {i18n.page.iban.label}:{' '}
                  {multiCartData?.gateway?.shop?.iban || ''}
                </AimTypography>
                <div style={{ display: 'flex' }}>
                  <AimTypography variant="text">
                    {i18n.page.reasonForPayment.label.toUpperCase()}:
                  </AimTypography>
                  <AimTypography variant="text">
                    {multiCartData?.gateway?.shop?.ibanCausal
                      ?.split('\n')
                      .map((i) => (
                        <>
                          {i}
                          <br />
                        </>
                      )) || ''}
                  </AimTypography>
                </div>
                <AimTypography variant="text">
                  {i18n.page.addressedTo.label.toUpperCase()}:{' '}
                  {multiCartData?.gateway?.shop?.addressedTo || ''}
                </AimTypography>
              </div>
            </CartRow>
          </>
        ) : null}
        <CartRow style={{ justifyContent: 'end' }}>
          <div
            style={{
              display: 'flex',
              marginTop: 10,
              alignItems: 'center',
              // flex: 1,
              // justifyContent: 'flex-end',
            }}
          >
            {multiCartData?.test.canChooseGateway &&
            multiCartData?.paymentType === constants.PaymentTypes.CreditCard ? (
              <AimSelect
                value={multiCartData?.gateway?.shop.gatewayType}
                onChange={onHandlePayment}
                displayEmpty
                formControlStyle={{
                  width: 220,
                  marginTop: 10,
                }}
                inputProps={{ 'aria-label': 'Without label' }}
                selectVariant={variant}
                selectPlaceholder="Choose payment method"
              >
                {paymentOptions
                  ? paymentOptions.map((item) => (
                      <AimSelectMenuItem
                        key={item.value}
                        value={item.value}
                        disabled={item.disabled}
                      >
                        {item.name}
                      </AimSelectMenuItem>
                    ))
                  : null}
              </AimSelect>
            ) : null}

            <AimIconAndTextButton
              isUpperCase
              variant="yellow"
              text={i18n.page.buy.label}
              style={{
                paddingLeft: 30,
                paddingRight: 30,
              }}
              disabled={
                // multiCartData.event.isEventEnd ||
                !multiCartData.paymentType
              }
              onClick={() => onClickConfirm(multiCartData.totals?.totalToPay)}
            >
              <ShoppingCartIcon />
            </AimIconAndTextButton>
          </div>
        </CartRow>
      </div>
    </div>
  );
};

export default Checkout;
