import React, { useEffect, useState, useRef } from 'react';

import ArrowBack from '@material-ui/icons/ArrowBack';
import ReplayIcon from '@material-ui/icons/Replay';
import CheckIcon from '@material-ui/icons/Check';

import { constants, appState } from '@aim/common';
import {
  AimIconAndTextButton,
  AimTypography,
  AimSnackbar,
  AimSnackbarSeverity,
} from './../../atoms';

import { ParticipationForm } from './ParticipationForm';
import {
  getEventDetails,
  getParticipationUserAndFieldValues,
} from './gqlHelper';
import translation from './translation';
import { format } from 'date-fns';
import { theme } from '@aim/components';

// divide standard and custom fields for the ui
// use baseFields
// make good use of profiles and typologies from event
// filter results by router state

const showLoader = () => appState.isLoader.next(true);

const hideLoader = () => appState.isLoader.next(false);

const pNew = {
  givenName: '',
  familyName: '',
  email: '',
  isSpeaker: false,
  isReviewer: false,
  isFaculty: false,
};

export const ParticipationEdit = ({
  intl,
  backUrl,
  onSave,
  editFrontOffice = false,
  isPostDeadline,
  isNameChange,
  sponsorAdmins = [],
  isAdminEnabled,
  eventId,
  participationData,
  participationIdList,
  history,
  requiredBaseFields = [],
  toSkipBaseFieldsIds = [],
  toHideBaseFields = [],
  isRequiredEnabled,
  containerStyle = {},
  customFormControlStyle,
  refButton,
  variant = 'grey',
}) => {
  const i18n = translation.participationEdit(intl);
  const saveDataRef = useRef();

  const [currentParticipation, setCurrentParticipation] = useState();
  const [profiles, setProfiles] = useState([]);
  const [feeDateRanges, setFeeDateRanges] = useState([]);
  const [typologies, setTypologies] = useState([]);
  const [preTitles, setPreTitles] = useState([]);
  const [snackbar, setSnackbar] = useState({ isOpen: false });
  const [user, setUser] = useState();

  useEffect(() => {
    // Get cognitoId of the user logged to know if it is Congress or Travel
    const subscription = appState.user.subscribe(setUser);
    return () => subscription.unsubscribe();
  }, []);

  useEffect(() => {
    if (!eventId && !participationData.id) return;

    const getData = async () => {
      showLoader();
      const evDetails = await getEventDetails(
        eventId,
        participationData.cluster,
        false
      );

      // filter profile by active feebrackets
      let itemsProfiles = evDetails.profiles.items;

      let filteredProfilesByCluster = itemsProfiles.filter(
        (profile) =>
          profile.cluster === participationData.cluster ||
          profile.clusters?.some(
            (cluster) => cluster === participationData.cluster
          )
      );

      if (editFrontOffice) {
        itemsProfiles = evDetails.profiles.items.filter((p) =>
          p.profileFeeBrackets.items.some(
            (i) => i.profileFeeConfiguration?.isActive
          )
        );
      }

      const validProfiles = filteredProfilesByCluster.filter((profile) => {
        // Find if profile contains a key equals to partcipationData.cluster
        console.log('profile', profile);

        // pax has not clusterids
        if (
          participationData.cluster === constants.Clusters.Pax.id &&
          profile.clusters?.includes('pax')
        ) {
          return true;
        } else {
          const clustersKey = Object.keys(profile).find(
            (key) => key === participationData.cluster
          );

          const clustersIds = profile[clustersKey];

          if (!clustersIds || clustersIds?.length === 0) return false;
          // Find if profile specific cluster field contains the participationData.clusterId or 'all'
          return clustersIds.some(
            (clusterId) =>
              clusterId === participationData.clusterId || clusterId === 'all'
          );
        }
      });
      console.log('validProfiles', validProfiles);

      const nextProfiles = validProfiles.map((profile) => ({
        label:
          profile.name +
          (profile?.category?.name ? ` - ${profile.category.name}` : ''),
        value: profile.id,
        maxAccompanyingPersons: profile.maxAccompanyingPersons,
      }));

      setProfiles(nextProfiles);

      const nextFeeDateRanges = evDetails?.fee?.feeDateRanges.items.map(
        ({ id, start, end, label }) => {
          const eventDateStart = format(new Date(start), 'PP');
          const eventDateEnd = format(new Date(end), 'PP');
          const nextLabel =
            eventDateStart === eventDateEnd
              ? `${label} - ${eventDateStart}`
              : `${label} - ${eventDateStart} - ${eventDateEnd}`;
          return {
            value: id,
            label: nextLabel,
          };
        }
      );
      setFeeDateRanges(nextFeeDateRanges);

      const nextTypologies = evDetails.participationTypologies.items.map(
        (participationTypology) => ({
          label: participationTypology.name,
          value: participationTypology.id,
        })
      );
      setTypologies(nextTypologies);

      const nextPreTitles = evDetails.preTitles.items
        .filter((preTitle) => preTitle.isActive)
        .map((p) => ({
          label: p.title,
          value: p.key,
        }));
      setPreTitles(nextPreTitles);
      let p = {
        ...pNew,
        ...participationData,
        clusterId: participationData.clusterId,
        event: { id: eventId, type: evDetails.type },
      };

      if (participationData.cluster === constants.Clusters.Delegations.id)
        p.delegation = { id: participationData.clusterId };

      if (participationData.cluster === constants.Clusters.Groups.id)
        p.group = { id: participationData.clusterId };

      if (participationData.cluster === constants.Clusters.SponsorStaff.id)
        p.sponsorStaff = { id: participationData.clusterId };

      if (participationData.cluster === constants.Clusters.SponsorList.id)
        p.sponsorList = { id: participationData.clusterId };

      if (participationData.id) {
        p = await getParticipationUserAndFieldValues(
          participationData.id,
          false
        );
        if (!p) {
          setSnackbar({
            isOpen: true,
            severity: AimSnackbarSeverity.error,
            message: 'No participation found',
          });
          return;
        }
      }
      if (isNameChange) {
        setCurrentParticipation({
          id: p.id,
          username: p.username,
          status: p.status,
          cluster: p.cluster,
          clusterId: p.clusterId,
          type: p.type,
          n2nConnection: p.n2nConnection,
          event: p.event,
          profile: p.profile,
          typology: p.typology,
          delegations: p.delegations,
          sponsorStaff: p.sponsorStaff,
          feeDateRange: p.feeDateRange?.id,
          guests: p.guests,
        });
      } else {
        setCurrentParticipation({ ...p, feeDateRange: p.feeDateRange?.id });
      }
    };

    getData();
  }, [eventId, participationData.id]);

  const sendData = async (submittedData, dirtyFields) => {
    onSave &&
      (await onSave({
        submittedData,
        currentParticipation,
        backUrl,
        dirtyFields,
      }));
  };

  const handleSave = () => {
    if (!saveDataRef.current) return;
    saveDataRef.current.click();
  };

  const getSaveText = () => {
    return isPostDeadline ? i18n.actions.request : i18n.actions.save;
  };

  const buildToSkipBaseFieldsIds = () => {
    let fields = [];

    if (user && user.type === constants.UserTypes.TRAVEL.id)
      fields = [
        ...fields,
        // 'typology',
        'isSpeaker',
        'feeDateRange',
        'isReviewer',
        'isFaculty',
      ];

    if (!isAdminEnabled) fields = [...fields, 'isSponsorAdmin'];

    if (currentParticipation?.cluster === constants.Clusters.SponsorStaff.id)
      fields = [...fields, 'isSpeaker', 'isReviewer', 'isFaculty'];

    return fields;
  };

  return (
    <>
      {backUrl && (
        <>
          <div style={{ display: 'flex', flex: 1, width: '100%' }}>
            <AimIconAndTextButton
              variant="none"
              text={i18n.actions.backButton.labels.back}
              style={{ padding: 0 }}
              onClick={() => history.push(backUrl)}
            >
              <ArrowBack />
            </AimIconAndTextButton>
          </div>
          <AimTypography variant="h2">
            {currentParticipation
              ? `${currentParticipation.givenName} ${currentParticipation.familyName}` ||
                currentParticipation.email
              : ''}
          </AimTypography>
        </>
      )}
      <div
        style={{
          background:
            variant === 'grey' || variant === 'primary'
              ? 'white'
              : theme.colors.greyScale.backgroundGrey,
          borderRadius: 4,
          padding: '20px 40px',
          margin: '20px 0px',
          ...containerStyle,
        }}
      >
        <ParticipationForm
          {...{
            customFormControlStyle,
            intl,
            participation: currentParticipation,
            profiles,
            typologies,
            preTitles,
            feeDateRanges,
            refButton: refButton ? refButton : saveDataRef,
            onSaveDataFn: sendData,
            toSkipBaseFieldsIds: toSkipBaseFieldsIds.length
              ? [...toSkipBaseFieldsIds, ...buildToSkipBaseFieldsIds()]
              : buildToSkipBaseFieldsIds(),
            toDisableBaseFields:
              sponsorAdmins.length &&
              !sponsorAdmins.includes(currentParticipation?.id)
                ? ['isSponsorAdmin']
                : [],
            requiredBaseFields,
            toHideBaseFields,
            isRequiredEnabled,
            onLoadEnd: hideLoader,
            variant: variant,
          }}
        />
        {!refButton ? (
          <div
            style={{
              display: 'flex',
              flex: 1,
              justifyContent: 'flex-end',
              marginTop: 20,
            }}
          >
            {backUrl && (
              <AimIconAndTextButton
                variant="greyFill"
                text={i18n.actions.cancel}
                onClick={() => history.push(backUrl)}
              >
                <ReplayIcon />
              </AimIconAndTextButton>
            )}
            <AimIconAndTextButton
              variant="greenFill"
              text={getSaveText()}
              onClick={handleSave}
            >
              <CheckIcon />
            </AimIconAndTextButton>
          </div>
        ) : null}
      </div>
      <AimSnackbar
        open={snackbar.isOpen}
        onClose={() => setSnackbar({ isOpen: false })}
        severity={snackbar.severity}
      >
        {snackbar.message}
      </AimSnackbar>
    </>
  );
};
