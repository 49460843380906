import { constants, googleHelper } from '@aim/common';

const {
  getGoogleMapsCountriesByInput,
  getGoogleMapsPlaceByPlaceId,
} = googleHelper;

export const getFieldValueByFieldDefinitionKey = (
  participation,
  key,
  defaultValue
) =>
  JSON.parse(
    participation?.fieldValues?.items?.find?.(
      (i) => i?.fieldDefinition?.key === key
    )?.value ?? JSON.stringify(defaultValue || '')
  );

export const extractBillingInformationFromFieldValues = async (
  participation,
  joinNameAndSurname = true,
  invoiceTo = constants.InvoiceToType.INDIVIDUAL.id
) => {
  let country = '';
  let countryIsoCode = '';

  const countryField = getFieldValueByFieldDefinitionKey(
    participation,
    'country'
  );

  try {
    const countriesRes = await getGoogleMapsCountriesByInput(countryField);
    if (countriesRes?.length === 1) {
      const countryRes = (
        await getGoogleMapsPlaceByPlaceId(countriesRes[0].id)
      )?.address_components?.find?.((ac) => ac.types?.includes?.('country'));
      countryIsoCode = countryRes.short_name || '';
      country = countriesRes[0].label || '';
    }
  } catch (e) {
    console.error('extractBillingInformationFromFieldValues', e);
  }

  if (invoiceTo === constants.InvoiceToType.COMPANY.id) {
    return {
      invoiceTo: constants.InvoiceToType.COMPANY.id,
      name: getFieldValueByFieldDefinitionKey(participation, 'authority'),
      city: getFieldValueByFieldDefinitionKey(participation, 'authority_city'),
      address: getFieldValueByFieldDefinitionKey(
        participation,
        'authority_address'
      ),
      postalCode: getFieldValueByFieldDefinitionKey(
        participation,
        'authority_post_code'
      ),
      province: getFieldValueByFieldDefinitionKey(
        participation,
        'authority_province'
      ),
      region: getFieldValueByFieldDefinitionKey(
        participation,
        'authority_region'
      ),
      country: getFieldValueByFieldDefinitionKey(
        participation,
        'authority_country'
      ),
      phone: getFieldValueByFieldDefinitionKey(
        participation,
        'authority_phone'
      ),
      countryIsoCode,
      vatCode: getFieldValueByFieldDefinitionKey(participation, 'vat_code'),
      taxCode: getFieldValueByFieldDefinitionKey(participation, 'fiscal_code'),
      reference: '',
      email: participation?.email || '',
      pec: '',
      invoiceToPublicAuthority: false,
      uniqueCode: '',
      ipaCode: '',
      isSplitPayment: false,
      isVatEvent: false,
    };
  } else {
    return {
      invoiceTo: constants.InvoiceToType.INDIVIDUAL.id,
      name: `${participation?.givenName || ''} ${
        joinNameAndSurname ? participation?.familyName || '' : ''
      }`.trim(),
      ...(joinNameAndSurname
        ? {}
        : { surname: `${participation?.familyName || ''}`.trim() }),
      address: getFieldValueByFieldDefinitionKey(participation, 'address'),
      city: getFieldValueByFieldDefinitionKey(participation, 'city'),
      postalCode: getFieldValueByFieldDefinitionKey(participation, 'zip_code'),
      region: getFieldValueByFieldDefinitionKey(participation, 'region'),
      province: getFieldValueByFieldDefinitionKey(participation, 'province'),
      country,
      countryIsoCode,
      vatCode: getFieldValueByFieldDefinitionKey(participation, 'vat_code'),
      taxCode: getFieldValueByFieldDefinitionKey(participation, 'fiscal_code'),
      reference: '',
      email: participation?.email || '',
      pec: '',
      phone: participation?.phone || '',
      invoiceToPublicAuthority: false,
      uniqueCode: '',
      ipaCode: '',
      isSplitPayment: false,
      isVatEvent: false,
    };
  }
};
