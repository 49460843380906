import React, { useState, useEffect } from 'react';

import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';

import {
  AimDialog,
  AimDragDropFileUploader,
  AimSelect,
  AimSelectMenuItem,
  AimTypography,
  AimCheckbox,
  AimTextField,
} from '@aim/components';
import { fileHelper, appState, constants } from '@aim/common';

const ManualMailTemplateDialogComponent = ({
  dialog,
  setDialog,
  mailTemplates,
  openPreviewDialog,
  i18n,
  eventId,
  intl,
  templateType,
}) => {
  const [user, setUser] = useState();
  const [selectedTemplate, setSelectedTemplate] = useState('');
  const [attachments, setAttachments] = useState([]);
  const s3AttachmentFolder = `events/${eventId}/customMailAttachments`;
  const [iAmTheSender, setIAmTheSender] = useState();
  const [
    skipParticipationsWithoutFiles,
    setSkipParticipationsWithoutFiles,
  ] = useState();
  const [replyToMe, setReplyToMe] = useState();
  const [cc, setCc] = useState();
  const [bcc, setBcc] = useState();
  const [sender, setSender] = useState();
  const [senderName, setSenderName] = useState();
  const [replyTo, setReplyTo] = useState();

  useEffect(() => {
    if (!dialog) return;

    const subscription = appState.user.subscribe(setUser);
    setSelectedTemplate();
    setAttachments([]);
    setReplyToMe(false);
    setIAmTheSender(false);
    setSelectedTemplate('');
    setSenderName('');
    setSender('');
    setReplyTo('');
    setCc('');
    setBcc('');
    setSkipParticipationsWithoutFiles(false);

    return () => subscription.unsubscribe();
  }, [
    dialog.participations,
    dialog.sponsors,
    dialog.products,
    dialog.abstract,
    dialog.purchases,
  ]);

  const openPreview = () => {
    const template = mailTemplates.find((x) => x.id === selectedTemplate);
    if (template) {
      openPreviewDialog({
        body: {
          ...dialog,
          trigger: template.trigger,
          // participations: dialog.participations,
          // abstracts: dialog.abstracts,
          eventId,
          attachments: attachments,
          sender: sender
            ? { Name: senderName || sender.trim(), Email: sender.trim() }
            : undefined,
          replyTo,
          cc: cc ? [cc.trim()] : undefined,
          bcc: bcc ? [bcc.trim()] : undefined,
          skipPaxWithNoFiles: skipParticipationsWithoutFiles,
        },
        template,
      });
    }
  };

  const uploadFiles = async (files) => {
    const nextAttachments = await Promise.all(
      files.map(async (file) => {
        const originalName = file?.name || new Date().getTime().toString();

        const matchExtension = originalName.match(/\.[0-9a-z]+$/i);
        const extension = matchExtension ? matchExtension[0] : '';

        const fileSize = file?.size;

        const input = {
          originalName: originalName,
          extension: extension,
          size: Number(fileSize) || 0,
        };

        const fileId = await fileHelper.createFile({
          dirPath: s3AttachmentFolder,
          fileData: input,
          localFile: file,
        });
        return { ...input, name: originalName, id: fileId };
      })
    );
    setAttachments((attachments) => [...attachments, ...nextAttachments]);
  };

  const removeFile = async (file) => {
    await fileHelper.deleteFile({
      dirPath: s3AttachmentFolder,
      fileData: file,
    });
    setAttachments((attachments) =>
      attachments.filter((a) => a.id !== file.id)
    );
  };

  const handleIAmTheSender = (e) => {
    if (e.target.checked) {
      setSender(user.email);
      setSenderName(`${user.givenName} ${user.familyName}`);
    } else {
      setSender('');
      setSenderName('');
    }
    setIAmTheSender(e.target.checked);
  };
  const handleReplyToMe = (e) => {
    if (e.target.checked) {
      setReplyTo(user.email);
    } else {
      setReplyTo('');
    }
    setReplyToMe(e.target.checked);
  };

  const handleSkipParticipationsWithoutFiles = (e) => {
    setSkipParticipationsWithoutFiles(e.target.checked);
  };

  return (
    <AimDialog
      disableEnforceFocus
      fullWidth
      maxWidth="xl"
      onAgree={openPreview}
      disableOnClose
      title={i18n.sendMailTemplateDialog.title}
      open={dialog.active === 'template' && dialog.isOpen}
      onClose={() => setDialog({ isOpen: false })}
    >
      <Grid container>
        <Grid item xs={12}>
          <AimSelect
            selectVariant="grey"
            label={`Select Mail Template`}
            value={selectedTemplate}
            hasNoneValue
            onChange={(e) => {
              setSelectedTemplate(e.target.value);
            }}
            formControlStyle={{ width: 'calc(100% - 30px)' }}
          >
            {mailTemplates.map((template) => {
              return (
                <AimSelectMenuItem key={template.id} value={template.id}>
                  {template.name}
                </AimSelectMenuItem>
              );
            })}
          </AimSelect>
        </Grid>
        <Grid item xs={12}>
          <AimDragDropFileUploader
            dragTitle={`attach files`}
            dragSubTitle="files"
            clickText="Choose files"
            fileLimitText="Max files 5"
            onUploadFile={uploadFiles}
            onRemove={removeFile}
            files={attachments}
            multiple
            maxFiles={5}
            maxSize={5242880}
            intl={intl}
          />
        </Grid>
        <Grid item xs={12}>
          <AimTypography>{'SENDER'}</AimTypography>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <AimCheckbox
            label={'I am the sender'}
            onChange={handleIAmTheSender}
            checked={iAmTheSender}
            labelPlacement={'right'}
            labelVariant={'text'}
          />
        </Grid>
        <Grid item xs={12}>
          <AimTextField
            label={'Sender Name:'}
            placeholder={'Enter sender name'}
            formControlStyle={{ width: '95%' }}
            disabled={iAmTheSender}
            value={senderName}
            onChange={(e) => setSenderName(e.target.value)}
          />
        </Grid>
        <Grid item xs={12}>
          <AimTextField
            label={'Sender:'}
            placeholder={'Enter email address'}
            formControlStyle={{ width: '95%' }}
            disabled={iAmTheSender}
            value={sender}
            onChange={(e) => setSender(e.target.value)}
          />
        </Grid>
        <Grid item xs={12}>
          <AimTypography>{'REPLY TO'}</AimTypography>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <AimCheckbox
            label={'Reply to me'}
            onChange={handleReplyToMe}
            checked={replyToMe}
            labelPlacement={'right'}
            labelVariant={'text'}
          />
        </Grid>
        <Grid item xs={12}>
          <AimTextField
            label={'Reply to:'}
            placeholder={'Enter email address'}
            formControlStyle={{ width: '95%' }}
            disabled={replyToMe}
            value={replyTo}
            onChange={(e) => setReplyTo(e.target.value)}
          />
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <AimTextField
            label={'Cc:'}
            placeholder={'Enter email address'}
            formControlStyle={{ width: '95%' }}
            value={cc}
            onChange={(e) => setCc(e.target.value)}
          />
        </Grid>
        <Grid item xs={12}>
          <AimTextField
            label={'Bcc:'}
            placeholder={'Enter email address'}
            formControlStyle={{ width: '95%' }}
            value={bcc}
            onChange={(e) => setBcc(e.target.value)}
          />
          <Divider />
        </Grid>
        {templateType === constants.EmailTemplateTypes.PARTICIPATION.key ? (
          <Grid item xs={12}>
            <AimCheckbox
              label={'Skip participations without files'}
              onChange={handleSkipParticipationsWithoutFiles}
              checked={skipParticipationsWithoutFiles}
              labelPlacement={'right'}
              labelVariant={'text'}
            />
          </Grid>
        ) : null}
      </Grid>
    </AimDialog>
  );
};

export const ManualMailTemplateDialog = React.memo(
  ManualMailTemplateDialogComponent
);
