import React, { useState, useEffect, useRef } from 'react';
// import { useHistory, useParams } from 'react-router-dom';
// import { useIntl } from 'react-intl';

import ArrowBack from '@material-ui/icons/ArrowBack';
import Tooltip from '@material-ui/core/Tooltip';

import { customAlphabet } from 'nanoid';
const alphabet = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ';
const nanoid = customAlphabet(alphabet, 6);

import { createPayment, updatePayment } from './paymentGqlHelper';
import {
  createProduct,
  updateProduct,
  createBillingInformation,
  createGuest,
  getEvent,
} from './productGqlHelper';

import {
  createHotelReservation,
  createHotelRoomReservation,
  // updateHotelRoom,
  getLastBookingCode,
} from './hotelReservationGqlHelper';
import { useDataHelper } from '../../../utils/hooks/dataHelper';

import {
  AimTypography,
  AimIconAndTextButton,
  AimSnackbarSeverity,
  AimSnackbar,
  // styled,
} from '../../atoms';
import { constants, appState, aws } from '@aim/common';

import Checkout from './Checkout';
import { MainContainer, InnerContainer } from './Containers';
import translation from './translation';

const showLoader = () => appState.isLoader.next(true);
const hideLoader = () => appState.isLoader.next(false);

export const PaymentCart = ({
  eventId,
  intl,
  history,
  isFrontOfficePurchase,
}) => {
  const i18n = translation.payment(intl);
  const { encodeDbNumber } = useDataHelper();

  const gateway = history.location?.state?.gateway;
  const paymentType =
    history.location?.state?.paymentType || gateway?.shop?.paymentType;
  // get amount / paymentType for payment; get id / type for product
  const netPrice = history.location?.state?.netPrice || 0;
  let productId = history.location?.state?.productId;
  const isService = history.location?.state?.isService;
  // const isClient = history.location?.state?.isClient;
  const serviceId = history.location?.state?.serviceId;
  const serviceType = history.location?.state?.serviceType;
  const additionalServices = history.location?.state?.additionalServices;
  const clientId = history.location?.state?.clientId;
  const clientType = history.location?.state?.clientType;
  const serviceLabel = history.location?.state?.serviceLabel;
  const serviceDescr = history.location?.state?.serviceDescr;
  const vat = history.location?.state?.vat;
  let paymentMetadata = history.location?.state?.paymentMetadata;
  const relUrl = history.location?.state?.relUrl;
  const baseUrl = history.location?.state?.baseUrl;
  const options = history.location?.state?.options;
  const billingInfo = history.location?.state?.billingInfo;
  const quantity = history.location?.state?.quantity;
  const maxQuantity = history.location?.state?.maxQuantity;

  const checkoutData = {
    services: [
      {
        isService: isService,
        serviceLabel: serviceLabel,
        serviceDescr: serviceDescr,
        servicesIncluded: additionalServices
          ? additionalServices.map((service) => ({
              id: service.id,
              title: service.title,
              type: constants.ProductType?.[service.typology],
            }))
          : [],
        quantity: {
          value: quantity || 1,
          max: maxQuantity || 1,
          readonly: false,
        },
        netPrice: netPrice,
        vat: vat,
        discount: { disabled: true },
      },
    ],
    gateway,
    paymentType,
    totals: { discount: { disabled: true } },
    event: {
      id: eventId,
    },
    test: {
      canChooseGateway: false,
      paymentType: 'creditCard',
      discountSelect: false,
    },
  };

  // states
  const [snackbar, setSnackbar] = useState({ isOpen: false });
  const [paymentGateway, setPaymentGateway] = React.useState(
    gateway?.shop?.gatewayType
  );
  const [responsePaymentIngenico, setResponsePaymentIngenico] = useState();

  // const [autoBuy, setAutoBuy] = useState(false);

  const eventCodeRef = useRef();
  const participationTypeRef = useRef();

  const PAYMENT_GATEWAY = {
    MONETAONLINE: 'MONETAONLINE',
    INGENICO: 'INGENICO',
    GPWEBPAY: 'GPWEBPAY',
  };

  useEffect(() => {
    const fetchEvent = async () => {
      const response = await getEvent(eventId);
      eventCodeRef.current = response.code;
    };
    fetchEvent();
  }, []);

  const handlePaymentChange = (event) => {
    setPaymentGateway(event.target.value);
  };

  const initPaymentMonetaOnLine = async (paymentId, relurl) => {
    showLoader();
    try {
      // const testurl = 'https://test.d1zwmjatj8p4ws.amplifyapp.com';
      // const baseurl = process.env.BASEURL.split('//')[1]; // testurl.split('//')[1] ||
      const apiName = 'aimMonetaOnLine';
      const path = '/monetaonline/initpayment';
      const queryData = {
        queryStringParameters: {
          paymentId,
          url: baseUrl,
          relurl: relurl.substring(1),
        },
        responseType: 'json',
      };

      const response = await aws.standardAPI.get(apiName, path, queryData);
      return response;
    } catch (e) {
      console.error('error ', e);
      setSnackbar({
        isOpen: true,
        severity: AimSnackbarSeverity.error,
        message: i18n.page.errors.initPayment.label,
      });
    } finally {
      hideLoader();
    }
  };

  const initPaymentIngenico = async (paymentId, relurl) => {
    showLoader();
    try {
      // const testurl = 'https://test.d1zwmjatj8p4ws.amplifyapp.com';
      // const baseurl = process.env.BASEURL.split('//')[1]; // testurl.split('//')[1] ||
      const apiName = 'aimIngenico';
      const path = '/ingenico/initpayment';
      const queryData = {
        queryStringParameters: {
          paymentId,
          url: baseUrl,
          relurl: relurl.substring(1),
        },
        responseType: 'json',
      };

      const response = await aws.standardAPI.get(apiName, path, queryData);
      return response;
    } catch (e) {
      console.error('error ', e);
      setSnackbar({
        isOpen: true,
        severity: AimSnackbarSeverity.error,
        message: i18n.page.errors.initPayment.label,
      });
    } finally {
      hideLoader();
    }
  };

  const initGpWebpay = async (paymentId, relurl) => {
    showLoader();
    try {
      // const baseurl = process.env.BASEURL.split('//')[1]; // testurl.split('//')[1] ||
      const apiName = 'aimGpWebPay';
      const path = '/gpwebpay/initpayment';

      const queryData = {
        queryStringParameters: {
          paymentId,
          url: baseUrl,
          relurl: relurl.substring(1),
        },
        responseType: 'json',
      };

      const response = await aws.standardAPI.get(apiName, path, queryData);
      return response;
    } catch (e) {
      console.error('error ', e);
      setSnackbar({
        isOpen: true,
        severity: AimSnackbarSeverity.error,
        message: i18n.page.errors.initPayment.label,
      });
    } finally {
      hideLoader();
    }
  };

  const initGateway = async (payment) => {
    // init gpwebpay
    if (paymentGateway === PAYMENT_GATEWAY.GPWEBPAY) {
      // init payment GpWebpay
      const response = await initGpWebpay(
        payment,
        `${history.location?.state?.url}${relUrl ? '/' + relUrl : ''}`
      );
      if (response.url) {
        window.location = response.url;
        // setResponseGpWebpay(response.html);
      } else {
        // history.push(
        //   `/events/${eventId}/${sponsorId}/checkout-error?errorcode=${response.errorcode}&merchantorderid=${purchase.id}`
        // );
        history.push(`${history.location?.state?.url}`);
      }
    }

    // init ingenico
    if (paymentGateway === PAYMENT_GATEWAY.INGENICO) {
      // init payment Ingenico
      const response = await initPaymentIngenico(
        payment,
        `${history.location?.state?.url}${relUrl ? '/' + relUrl : ''}`
      );
      setResponsePaymentIngenico(response.html);
    }

    // init monetaonline
    if (paymentGateway === PAYMENT_GATEWAY.MONETAONLINE) {
      // get payment id from MonetaOnLine
      const response = await initPaymentMonetaOnLine(
        payment,
        `${history.location?.state?.url}${relUrl ? '/' + relUrl : ''}`
      );

      if (response.error > 0) {
        history.push(
          `${history.location?.state?.url}/checkout-error?errorcode=` +
            response.error
        );
      } else {
        const { paymentid, hostedpageurl } = response;
        window.location = `${hostedpageurl}?paymentid=${paymentid}`;
        // window.open(`${hostedpageurl}?paymentid=${paymentid}`, '_blank');
      }
    }
  };

  const createProdsForAdditionalServices = async (parentProductId) => {
    // add additional services to products and payments
    if (additionalServices) {
      additionalServices.map(async (additionalService) => {
        let nextAdditionalServicesPaymentId = additionalService.paymentTableId;
        if (!nextAdditionalServicesPaymentId) {
          const additionalServiceProduct = await createProduct(
            {
              serviceId, //eventId
              serviceType:
                constants.AdditionalServicesServiceTypology?.[
                  additionalService.typology
                ]?.productServiceType ||
                constants.AdditionalServicesServiceTypology.social
                  ?.productServiceType,
              clientId, // id participant
              clientType, // pax
              productId: additionalService.id,
              isFrontOfficePurchase,
              productChildsId: parentProductId,
              participationType: options.feeType,
            },
            false
          );

          // create payment to zero for additional service
          if (additionalServiceProduct.id) {
            const paymentId = `${eventCodeRef.current}-${nanoid()}`;
            const additionalServicePayment = await createPayment(
              {
                paymentId,
                paymentProductId: additionalServiceProduct.id,
                paymentType: constants.PaymentTypes.Free,
                date: new Date(),
                amount: 0,
                description: `${serviceLabel} ; additional service: ${additionalService.title}`,
                // paymentMetadata: JSON.stringify({
                //   ...paymentMetadata,
                //   amount: 0,
                // }),
              },
              false
            );
            if (additionalServicePayment.id) {
              await updateProduct(
                {
                  id: additionalServiceProduct.id,
                  productPaymentId: additionalServicePayment.id,
                },
                false
              );
            }

            nextAdditionalServicesPaymentId = additionalServicePayment.id;
          }
        }
        await createBillingInfo(nextAdditionalServicesPaymentId);
      });
    }
  };

  const createBillingInfo = async (paymentId) => {
    // eslint-disable-next-line unused-imports/no-unused-vars
    const { id, ...restBillingInfo } = billingInfo;
    const billingInfoResp = await createBillingInformation({
      ...restBillingInfo,
      country:
        restBillingInfo?.country?.value || restBillingInfo?.country || '',
      city: restBillingInfo?.city?.value || restBillingInfo?.city || '',
    });

    await updatePayment(
      {
        id: paymentId,
        paymentBillingInformationId: billingInfoResp.id,
      },
      false
    );
  };

  const addGuests = async (productId) => {
    const guests = appState.purchaseData.getValue()?.guests;
    if (guests?.length > 0) {
      await Promise.all(
        guests.map(async (guest) => {
          delete guest.id;
          delete guest.countryObj;
          await createGuest(
            {
              ...guest,
              productGuestsId: productId,
            },
            false
          );
        })
      );
    }
  };

  const createAllotmentReservation = async () => {
    const purchaseData = appState.purchaseData.getValue();
    // console.log('purchasedData ', purchaseData);
    const respHotelReservation = await createHotelReservation({
      start: purchaseData.start,
      end: purchaseData.end,
      status: purchaseData.status,
      isDeleted: 'false',
      hotelReservationEventId: purchaseData.eventId,
      hotelReservationParticipationId: clientId,
    });

    await Promise.all(
      // !!! todo: getLastBookingCode to move in lambda function for concurrency issues
      purchaseData.hotelRoomsToReserve.map(async (room) => {
        let lastBookingCode = await getLastBookingCode(
          options?.hotelEventVentureId
        );

        const respHotelRoomReservation = await createHotelRoomReservation({
          startDate: purchaseData.start,
          endDate: purchaseData.end,
          depositAmount: room.amount,
          balanceAmount: room.amount,
          notes: room.notes,
          availability: room.availability,
          hotelRoomReservationHotelEventVentureId: options?.hotelEventVentureId,
          hotelRoomReservationEventId: eventId,
          hotelRoomReservationHotelReservationId: respHotelReservation.id,
          hotelRoomReservationHotelRoomId: room.id,
          bookingCode: lastBookingCode + 1 || 1,
        });

        room.guests.map(async (guest) => {
          await createGuest({
            givenName: guest.name,
            familyName: guest.surname,
            email: guest.email,
            phone: guest.phone,
            ...(guest.isPax && { guestParticipationId: guest.id }),
            guestHotelRoomReservationId: respHotelRoomReservation.id,
          });
        });

        // add reservation to payment metadata before create payment
        paymentMetadata = {
          ...paymentMetadata,
          hotelReservationId: respHotelReservation.id,
          hotelRoomReservationId: respHotelRoomReservation.id,
        };
      })
    );
    return respHotelReservation.id;
  };

  // confirm payment: create product, create billinginfo and add guests linked to participant
  const onClickConfirm = async (amount) => {
    if (typeof amount !== 'number' && amount < 0) {
      setSnackbar({
        isOpen: true,
        severity: AimSnackbarSeverity.error,
        message: 'amount is not valid',
      });
      return;
    }

    showLoader();
    let product;

    // calculate total amount of the purchase
    const amountEncoded = encodeDbNumber(amount);

    if (serviceType === constants.ProductType.allotment) {
      // create hotel reservation
      productId = await createAllotmentReservation();
    }

    try {
      // create product

      const paymentId = history?.location?.state?.preselectedPaymentId
        ? history?.location?.state?.preselectedPaymentId
        : `${eventCodeRef.current}-${nanoid()}`;

      let tablePaymentId = history?.location?.state?.paymentData?.id;
      let newProductId = null;
      const productType = history?.location?.state?.options?.feeType;
      if (!history?.location?.state?.preselectedPaymentId) {
        let participationType;
        if (serviceType === constants.ProductType.registration) {
          participationType = options.feeType;
        } else if (
          serviceType === constants.ProductType.socialEvents ||
          serviceType === constants.ProductType.scientificEvents
        ) {
          participationType = options.participationType;
        } else if (serviceType === constants.ProductType.allotment) {
          participationType = constants.EventTypes.PHYSICAL.id;
        }

        product = await createProduct(
          {
            serviceId,
            serviceType,
            clientId,
            clientType,
            productId,
            isFrontOfficePurchase,
            productType,
            participationType,
            isPublished: true,
          },
          false
        );

        const payment = await createPayment(
          {
            paymentProductId: product.id,
            paymentType,
            date: new Date(),
            amount: amountEncoded,
            description: serviceLabel,
            paymentId,
            paymentStatus: constants.PaymentStatuses.PENDING.key,
            paymentMetadata: JSON.stringify({
              ...paymentMetadata,
              // amount: amountEncoded,
            }),

            // paymentType === constants.PaymentTypes.BankTransfer
            //   ? constants.PaymentStatuses.PENDING.key
            //   : constants.PaymentStatuses.DONE.key, //todo change this to always pending until lambda has been modified properly
          },
          false
        );

        tablePaymentId = payment.id;

        await updateProduct(
          {
            id: product.id,
            productPaymentId: tablePaymentId,
          },
          false
        );
        newProductId = product.id;

        // add guests linked to participant from frontoffice (registrations)
        // no guests for additional services: for each guest an additional service is purchased as for the participant
        if (serviceType === constants.ProductType.registration) {
          await addGuests(product.id);
        }
      }
      // create billing information for product
      // we create another billing info if is an already created product (preselectedPaymentId) to update values instead update billing information, the old value remains orphan
      await createBillingInfo(tablePaymentId);

      // if the payment type is credit card associate the payment to the product
      if (paymentType === constants.PaymentTypes.CreditCard) {
        if (
          serviceType === constants.ProductType.registration &&
          (product?.id || history?.location?.state?.preselectedPaymentId)
        ) {
          await createProdsForAdditionalServices(newProductId);
        }

        //reset purchase data
        appState.purchaseData.next();

        initGateway(tablePaymentId);
      } else if (paymentType === constants.PaymentTypes.BankTransfer) {
        // service type REGISTRATION: update pax and create products for additional services
        if (
          serviceType === constants.ProductType.registration &&
          (product?.id || history?.location?.state?.preselectedPaymentId)
        ) {
          await createProdsForAdditionalServices(newProductId);
        }

        //reset purchase data
        appState.purchaseData.next();

        history.push(
          `${history.location?.state?.url}/checkout-success?merchantorderid=${paymentId}`
        );
      } else if (
        paymentType === constants.PaymentTypes.Free &&
        amountEncoded === 0
      ) {
        // service type REGISTRATION: update pax and create products for additional services

        if (
          serviceType === constants.ProductType.registration &&
          (product?.id || history?.location?.state?.preselectedPaymentId)
        ) {
          await createProdsForAdditionalServices(newProductId);
        }

        //reset purchase data
        appState.purchaseData.next();

        history.push(`${history.location?.state?.url}/checkout-success`);
      }
    } catch (e) {
      console.error('error ', e);
      setSnackbar({
        isOpen: true,
        severity: AimSnackbarSeverity.error,
        message: i18n.page.errors.product.label,
      });
    } finally {
      hideLoader();
    }
  };

  return (
    <>
      <MainContainer>
        <div style={{ display: 'flex-root', marginTop: 20 }}>
          <InnerContainer>
            <Tooltip title={i18n.page.back.checkout.tooltip}>
              <AimIconAndTextButton
                variant="text"
                text={i18n.page.back.checkout.label}
                onClick={() => history.goBack()}
              >
                <ArrowBack />
              </AimIconAndTextButton>
            </Tooltip>
            <AimTypography variant={'h1'}>
              {i18n.page.title.checkout}
            </AimTypography>
          </InnerContainer>
        </div>
        <div>
          {parseInt(paymentGateway, 10) === PAYMENT_GATEWAY.INGENICO &&
          responsePaymentIngenico ? (
            <div
              className="content"
              dangerouslySetInnerHTML={{
                __html: responsePaymentIngenico,
              }}
            ></div>
          ) : (
            <InnerContainer bottomSpacing>
              <Checkout
                onClickConfirm={onClickConfirm}
                onHandlePayment={handlePaymentChange}
                i18n={i18n}
                // isClient={isClient}
                // clientId={clientId}
                // clientType={clientType}
                data={checkoutData}
                {...{
                  onClickConfirm,
                  onHandlePayment: handlePaymentChange,
                  i18n,
                  data: checkoutData,
                  //autoBuy,
                }}
              />
            </InnerContainer>
          )}
        </div>
      </MainContainer>

      <AimSnackbar
        open={snackbar.isOpen}
        onClose={() => setSnackbar({ isOpen: false })}
        severity={snackbar.severity}
      >
        {snackbar.message}
      </AimSnackbar>
    </>
  );
};
