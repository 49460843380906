import { aws, appState, constants } from '@aim/common';
const { API } = aws;

const showLoader = () => appState.isLoader.next(true);

const hideLoader = () => appState.isLoader.next(false);

const getEventDetailsQuery = /* GraphQL */ `
  query getEventDetails($id: ID!, $filter: ModelProfileFilterInput) {
    getEvent(id: $id) {
      id
      type
      profiles(filter: $filter) {
        items {
          id
          name
          isDeleted
          maxAccompanyingPersons
          profileFeeBrackets {
            items {
              id
              profileFeeConfiguration {
                id
                isActive
              }
            }
          }
          category {
            name
          }
          cluster
          clusters
          delegations
          groups
          sponsorstaff
          sponsorlist
        }
      }
      fee {
        feeDateRanges {
          items {
            id
            start
            end
            label
          }
        }
      }
      participationTypologies {
        items {
          id
          name
        }
      }
      preTitles {
        items {
          id
          key
          title
          preTitle
          isActive
        }
      }
    }
  }
`;

const getEventFieldsQuery = /* GraphQL */ `
  query getFieldsListByContexts(
    $id: ID!
    $filter: ModelFieldContextFilterInput
  ) {
    getEvent(id: $id) {
      standardFields(filter: { isDeleted: { ne: true } }) {
        items {
          key
          controlType
          id
          label
          options
          placeholder
          blockField {
            id
            order
            block {
              id
              firstField
            }
          }
          contextsOfUse(filter: $filter) {
            items {
              id
              position
              contextName
              isHidden
              isRequired
            }
          }
        }
      }
      services(filter: { serviceName: { eq: "PARTICIPATION" } }) {
        items {
          serviceName
          id
          customFields(filter: { isDeleted: { ne: true } }) {
            items {
              controlType
              id
              label
              options
              placeholder
              blockField {
                id
                order
                block {
                  id
                  firstField
                }
              }
              contextsOfUse(filter: $filter) {
                items {
                  id
                  position
                  contextName
                  isHidden
                  isRequired
                }
              }
            }
          }
        }
      }
    }
  }
`;

const listParticipationFieldValues = `
  query listParticipationFieldValues($id: ID!, $n2NConnectionType: String!) {
    getParticipation(id: $id) {
      id
      username
      type
      title
      givenName
      familyName
      email
      phone
      cognitoUserId
      status
      cluster
      notes
      seg
      ana
      isParticipant
      isSpeaker
      isFaculty
      isReviewer
      guests { 
        items {
          id
          givenName
          familyName
          country
        }
      }
      n2nConnection(filter: {type: {eq: $n2NConnectionType}}) {
        items {
          id
          n2NConnectionsTableAdditionalServicesId
          n2NConnectionsTableParticipationId
          type
          status
          additionalServices {
            id
            title
            typology
          }
        }
      }
      event {
        id
        type
      }
      profile {
        id
      }
      typology {
        id
      }
      delegations: delegation {
        id
      }
      sponsorStaff {
        id
        sponsor {
          id
        }
      }
      sponsor{
        id
      }
      feeDateRange {
        id
        label
        start
        end
      }
      fieldValues {
        items {
          id
          value
          fieldDefinition {
            id
            options
            label
            placeholder
            isDeleted
            controlType
          }
        }
      }
    }
  }
`;

const createParticipationServicesMutation = /* GraphQL */ `
  mutation CreateParticipationServices(
    $input: CreateParticipationServicesInput!
    $condition: ModelParticipationServicesConditionInput
  ) {
    createParticipationServices(input: $input, condition: $condition) {
      id
    }
  }
`;

const getEventCodeQuery = /* GraphQL */ `
  query getEvent($id: ID!) {
    getEvent(id: $id) {
      id
      code
    }
  }
`;

const createParticipationMutation = /* GraphQL */ `
  mutation CreateParticipation(
    $input: CreateParticipationInput!
    $condition: ModelParticipationConditionInput
  ) {
    createParticipation(input: $input, condition: $condition) {
      id
    }
  }
`;

const updateParticipationMutation = /* GraphQL */ `
  mutation UpdateParticipation(
    $input: UpdateParticipationInput!
    $condition: ModelParticipationConditionInput
  ) {
    updateParticipation(input: $input, condition: $condition) {
      id
    }
  }
`;

const createFieldValueMutation = /* GraphQL */ `
  mutation CreateFieldValue(
    $input: CreateFieldValueInput!
    $condition: ModelFieldValueConditionInput
  ) {
    createFieldValue(input: $input, condition: $condition) {
      id
    }
  }
`;

const updateFieldValueMutation = /* GraphQL */ `
  mutation UpdateFieldValue(
    $input: UpdateFieldValueInput!
    $condition: ModelFieldValueConditionInput
  ) {
    updateFieldValue(input: $input, condition: $condition) {
      id
    }
  }
`;

const deleteFieldValueMutation = /* GraphQL */ `
  mutation DeleteFieldValue(
    $input: DeleteFieldValueInput!
    $condition: ModelFieldValueConditionInput
  ) {
    deleteFieldValue(input: $input, condition: $condition) {
      id
    }
  }
`;

const createModificationRequest = /* GraphQL */ `
  mutation CreateModificationRequest(
    $input: CreateModificationRequestInput!
    $condition: ModelModificationRequestConditionInput
  ) {
    createModificationRequest(input: $input, condition: $condition) {
      id
    }
  }
`;

const updateModificationRequest = /* GraphQL */ `
  mutation UpdateModificationRequest(
    $input: UpdateModificationRequestInput!
    $condition: ModelModificationRequestConditionInput
  ) {
    updateModificationRequest(input: $input, condition: $condition) {
      id
    }
  }
`;

const createParticipationRequestMutation = /* GraphQL */ `
  mutation CreateParticipationRequest(
    $input: CreateParticipationRequestInput!
    $condition: ModelParticipationRequestConditionInput
  ) {
    createParticipationRequest(input: $input, condition: $condition) {
      id
    }
  }
`;

const createGuestMutation = /* GraphQL */ `
  mutation createGuest(
    $input: CreateGuestInput!
    $condition: ModelGuestConditionInput
  ) {
    createGuest(input: $input, condition: $condition) {
      id
    }
  }
`;

const updateGuestMutation = /* GraphQL */ `
  mutation UpdateGuest(
    $input: UpdateGuestInput!
    $condition: ModelGuestConditionInput
  ) {
    updateGuest(input: $input, condition: $condition) {
      id
    }
  }
`;

const deleteGuestMutation = /* GraphQL */ `
  mutation DeleteGuest(
    $input: DeleteGuestInput!
    $condition: ModelGuestConditionInput
  ) {
    deleteGuest(input: $input, condition: $condition) {
      id
    }
  }
`;

const getEventBlocksQuery = /* GraphQL */ `
  query GetEventBlocks($id: ID!) {
    getEvent(id: $id) {
      registrationFormBlocks {
        items {
          id
          name
          order
          description
          firstField
        }
      }
    }
  }
`;

const createLogMutation = /* GraphQL */ `
  mutation CreateLog(
    $input: CreateLogInput!
    $condition: ModelLogConditionInput
  ) {
    createLog(input: $input, condition: $condition) {
      id
    }
  }
`;

const getParticipantProductsQuery = /* GraphQL */ `
  query getParticipantProducts($clientId: String) {
    productByClientId(clientId: $clientId) {
      items {
        id
        serviceType
        productId
        clientId
        payment {
          id
          description
          paymentId
          paymentType
          paymentIdMonetaOnLine
          paymentIdIngenico
          paymentIdGpWebpay
          paymentError
          amount
          paymentStatus
          createdAt
          updatedAt
          isDeleted
          paymentMetadata
        }
      }
    }
  }
`;

export const createParticipationServices = (input, useLoader = true) =>
  new Promise((resolve, reject) => {
    useLoader && showLoader();
    aws.API.graphql({
      query: createParticipationServicesMutation,
      variables: { input },
    })
      .then(resolve)
      .catch((e) => {
        console.error('create-participation-service', e);
        reject(e);
      })
      .finally(hideLoader);
  });

export const getEventDetails = (eventId, cluster, isLoader = true) => {
  console.log('🚀 ~ cluster:', cluster);
  return new Promise((resolve, reject) => {
    isLoader && showLoader();
    API.graphql({
      query: getEventDetailsQuery,
      variables: {
        id: eventId,
        filter: {
          /* cluster: { eq: cluster }, */
          isDeleted: { ne: true },
        },
      },
    })
      .then((response) => resolve(response.data.getEvent))
      .catch((e) => {
        console.error('get-event-details', e);
        reject();
      })
      .finally(() => isLoader && hideLoader());
  });
};

export const getEventFields = ({ eventId, contextsOfUse, isLoader = true }) =>
  new Promise((resolve, reject) => {
    isLoader && showLoader();
    API.graphql({
      query: getEventFieldsQuery,
      variables: {
        id: eventId,
        filter: { or: contextsOfUse.map((c) => ({ contextName: { eq: c } })) },
      },
    })
      .then(({ data }) => {
        const result = {
          isParticipant: data.getEvent.isParticipant,
          isSpeaker: data.getEvent.isSpeaker,
          isReviewer: data.getEvent.isReviewer,
          standardFields: data.getEvent.standardFields.items,
          customFields: data.getEvent.services.items.length
            ? data.getEvent.services.items[0].customFields.items
            : [],
        };
        resolve(result);
      })
      .catch((e) => {
        console.error('list-event-field-by-context', e);
        reject();
      })
      .finally(() => isLoader && hideLoader());
  });

export const getParticipationUserAndFieldValues = (
  participationId,
  isLoader = true
) =>
  new Promise((resolve, reject) => {
    isLoader && showLoader();
    API.graphql({
      query: listParticipationFieldValues,
      variables: {
        id: participationId,
        n2NConnectionType:
          constants.N2NConnectionTypes.PARTICIPATION_ADDITIONAL_SERVICE,
      },
    })
      .then((response) =>
        resolve({
          ...response.data.getParticipation,
          clusterId:
            response.data.getParticipation?.delegations?.id ||
            response.data.getParticipation?.sponsorStaff?.id ||
            response.data.getParticipation?.sponsorList?.id ||
            response.data.getParticipation?.agencyGroup?.id,
        })
      )
      .catch((e) => {
        console.error('get-field-values', e);
        reject();
      })
      .finally(() => isLoader && hideLoader());
  });

export const createParticipation = (input, isLoader = true) =>
  new Promise((resolve, reject) => {
    isLoader && showLoader();
    API.graphql({
      query: createParticipationMutation,
      variables: { input },
    })
      .then((response) => resolve(response.data.createParticipation))
      .catch((e) => {
        console.error('create-participation', e);
        reject();
      })
      .finally(() => isLoader && hideLoader());
  });

export const updateParticipation = (input, isLoader = true) =>
  new Promise((resolve, reject) => {
    isLoader && showLoader();
    API.graphql({
      query: updateParticipationMutation,
      variables: { input },
    })
      .then((response) => resolve(response.data.updateParticipation))
      .catch((e) => {
        console.error('update-participation', e);
        reject();
      })
      .finally(() => isLoader && hideLoader());
  });

export const createFieldValue = (input, isLoader = true) =>
  new Promise((resolve, reject) => {
    isLoader && showLoader();
    API.graphql({
      query: createFieldValueMutation,
      variables: { input },
    })
      .then((response) => resolve(response.data.createFieldValue))
      .catch((e) => {
        console.error('update-field-value', e);
        reject();
      })
      .finally(() => isLoader && hideLoader());
  });

export const updateFieldValue = (input, isLoader = true) =>
  new Promise((resolve, reject) => {
    isLoader && showLoader();
    API.graphql({
      query: updateFieldValueMutation,
      variables: { input },
    })
      .then((response) => resolve(response.data.updateFieldValue))
      .catch((e) => {
        console.error('update-field-value', e);
        reject();
      })
      .finally(() => isLoader && hideLoader());
  });

export const deleteFieldValue = (input, isLoader = true) =>
  new Promise((resolve, reject) => {
    isLoader && showLoader();
    API.graphql({
      query: deleteFieldValueMutation,
      variables: { input },
    })
      .then((response) => resolve(response.data.deleteFieldValue))
      .catch((e) => {
        console.error('delete-field-value', e);
        reject();
      })
      .finally(() => isLoader && hideLoader());
  });

export const postDeadlineModificationRequestCreate = (
  input,
  useLoader = true
) =>
  new Promise((resolve, reject) => {
    useLoader && showLoader();

    API.graphql({
      query: createModificationRequest,
      variables: { input },
    })
      .then((response) => {
        const responseData = response.data.createModificationRequest;
        resolve(responseData);
      })
      .catch((e) => {
        console.error('create-modification-request', e);
        reject();
      })
      .finally(() => useLoader && hideLoader());
  });

export const postDeadlineModificationRequestUpdate = (
  input,
  useLoader = true
) =>
  new Promise((resolve, reject) => {
    useLoader && showLoader();

    API.graphql({
      query: updateModificationRequest,
      variables: { input },
    })
      .then((response) => {
        const responseData = response.data.updateModificationRequest;
        resolve(responseData);
      })
      .catch((e) => {
        console.error('udpate-modification-request', e);
        reject();
      })
      .finally(() => useLoader && hideLoader());
  });

export const createParticipationRequest = (input, useLoader = true) =>
  new Promise((resolve, reject) => {
    useLoader && showLoader();

    API.graphql({
      query: createParticipationRequestMutation,
      variables: { input },
    })
      .then((response) => {
        const responseData = response.data.createParticipationRequest;
        resolve(responseData);
      })
      .catch((e) => {
        console.error('create-participation-request', e);
        reject();
      })
      .finally(() => useLoader && hideLoader());
  });

export const createGuest = (input, isLoader = true) =>
  new Promise((resolve, reject) => {
    isLoader && showLoader();
    API.graphql({
      query: createGuestMutation,
      variables: { input },
    })
      .then((response) => resolve(response.data))
      .catch((e) => {
        console.error('create-guest', e);
        reject();
      })
      .finally(() => isLoader && hideLoader());
  });

export const updateGuest = (input, isLoader = true) =>
  new Promise((resolve, reject) => {
    isLoader && showLoader();
    API.graphql({
      query: updateGuestMutation,
      variables: { input },
    })
      .then((response) => resolve(response.data))
      .catch((e) => {
        console.error('update-guest', e);
        reject();
      })
      .finally(() => isLoader && hideLoader());
  });

export const deleteGuest = (input, isLoader = true) =>
  new Promise((resolve, reject) => {
    isLoader && showLoader();
    API.graphql({
      query: deleteGuestMutation,
      variables: { input },
    })
      .then((response) => resolve(response.data))
      .catch((e) => {
        console.error('delete-guest', e);
        reject();
      })
      .finally(() => isLoader && hideLoader());
  });

export const getEventCode = (eventId, isLoader = true) =>
  new Promise((resolve, reject) => {
    isLoader && showLoader();

    API.graphql({
      query: getEventCodeQuery,
      variables: { id: eventId },
    })
      .then((response) => {
        return resolve(response.data.getEvent.code);
      })
      .catch((e) => {
        console.error('get-event-code', e);
        reject();
      })
      .finally(() => isLoader && hideLoader());
  });
export const getEventBlocks = (eventId, isLoader = true) =>
  new Promise((resolve, reject) => {
    isLoader && showLoader();

    API.graphql({
      query: getEventBlocksQuery,
      variables: { id: eventId },
    })
      .then((response) => {
        return resolve(response.data.getEvent.registrationFormBlocks.items);
      })
      .catch((e) => {
        console.error('get-event-registration-blocks', e);
        reject();
      })
      .finally(() => isLoader && hideLoader());
  });

export const createLog = async (input) => {
  new Promise((resolve, reject) => {
    API.graphql({
      query: createLogMutation,
      variables: { input },
    })
      .then((response) => resolve(response.data.createLog))
      .catch((e) => {
        console.error('create-log-mutation', e);
        reject();
      });
  });
};

export const getParticipantProducts = ({ clientId, isLoader = true }) => {
  return new Promise((resolve, reject) => {
    isLoader && showLoader();
    API.graphql({
      query: getParticipantProductsQuery,
      variables: {
        clientId,
      },
    })
      .then(({ data }) => {
        resolve(data.productByClientId.items);
      })
      .catch((e) => {
        console.error('get-participant-products-error', e);
        reject();
      })
      .finally(() => isLoader && hideLoader());
  });
};
