import React from 'react';
import { AimCheckbox, AimTypography } from '../../atoms';
import { Divider } from '@material-ui/core';
import { fileHelper } from '@aim/common';

export const PrivacySectionForm = ({
  privacyFiles,
  state,
  changeState,
  isPreview,
  eventId,
}) => {
  const { downloadZipFromS3, downloadFromS3 } = fileHelper;

  const downloadFiles = async (files, privacyId) => {
    const s3Folder = `events/${eventId}/privacy/${privacyId}/`;
    if (files.length > 3) {
      const newFiles = files.map((f) => {
        return { dirPath: s3Folder, ...f };
      });

      downloadZipFromS3(newFiles, 'test.zip');
    } else {
      for (const file of files) {
        await downloadFromS3({ dirPath: s3Folder, fileData: file });
      }
    }
  };

  const getFieldText = (field, privacyId) => {
    const regex = new RegExp('<<([^>]+)>>', 'g');
    if (!isPreview) {
      return field.text.split(regex).map((text, idx) =>
        !!(idx % 2) ? (
          <span
            style={{
              textDecoration: 'underline',
              fontWeight: 700,
              color: '#30CFD0',
              cursor: 'pointer',
            }}
            onClick={() => downloadFiles(field.file, privacyId)}
          >
            {text}
          </span>
        ) : (
          text
        )
      );
    } else {
      return field.text.split(regex).map((text, idx) => text);
    }
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
      {privacyFiles.length > 0
        ? privacyFiles.map((p) => (
            <div
              key={p.id}
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '5px',
                width: '100%',
              }}
            >
              <AimTypography variant="textBold" style={{ lineHeight: 0 }}>
                {p.name}
              </AimTypography>
              <Divider />
              <AimTypography variant="text">{p.description}</AimTypography>
              {(p.fields || []).length > 0 &&
                p.fields.map((f, idx) => {
                  return (
                    <div
                      key={idx}
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '5px',
                      }}
                    >
                      <AimCheckbox
                        variant="grey"
                        name="privacy"
                        onChange={() => changeState(p.id, idx)}
                        disabled={isPreview}
                        checked={isPreview ? f.value : state[p.id][idx]}
                      />
                      <AimTypography variant="text">
                        {getFieldText(f, p.id)}
                      </AimTypography>
                    </div>
                  );
                })}
            </div>
          ))
        : null}
    </div>
  );
};
